/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
html, body {
}

@font-face {
  font-family: "Montserrat";
  src: url("fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("fonts/Inter-Regular.ttf");
}

footer {
  position: absolute;
  bottom: 5px;
  right: 20%;
}


/* @uncommon */

@keyframes spinny {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes beat {
    from {
        background-color: #666666;
    }
}

@keyframes fader {
    0% {
        background-color: #FFFFFFFF;
    }
    100% {
        background-color: #FFFFFF00;
    }
}

.beat {
}

.beat-on {
    animation: beat 1s linear;
}
